import React, {useState} from "react";
import Avatar from "../avatar/Avatar";
import Box from "../Box";
import Button from "../buttons/Button";
import FlexBox from "../FlexBox";
import Grid from "../grid/Grid";
import Icon from "../icon/Icon";
import Rating from "../rating/Rating";
import {H1, H2, H3, H6, SemiSpan} from "../Typography";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {useDispatch, useSelector} from "react-redux";

export interface ProductIntroProps {
    product: any
}

const ProductIntro: React.FC<ProductIntroProps> = ({product}) => {
    const [selectedImage, setSelectedImage] = useState(0);

    // @ts-ignore
    const cartItem = useSelector(state => state.cart.products.find((item) => item.id === product?.id))
    // @ts-ignore
    const settings = useSelector(state => state.settings.data)

    const handleImageClick = (ind) => () => {
        setSelectedImage(ind);
    };
    const dispatch = useDispatch()
    const handleCartAmountChange = amount => {
        dispatch({
            type: 'cart/update',
            payload: {
                id: product?.id,
                slug: product?.slug,
                name: product?.name,
                quantity: amount,
                price: +product?.discount_price,
                image: product?.image1,
            }
        })
    }
    let gallery = []
    if(product?.image1) {
        gallery = [product?.image1]
        if(product?.gallery?.length > 0) {
            gallery = [product?.image1, ...product?.gallery.map(image => image.image)]
        }
    }

    return (
        <Box overflow="hidden" style={{minWidth: 'min(98vw, 1200px)'}}>
            <SkeletonTheme baseColor="#f3f4f6" highlightColor="#fff">
                <Grid container justifyContent="center" spacing={16}>
                    <Grid item md={6} xs={12} alignItems="center">
                        <Box>
                            <FlexBox justifyContent="center" mb="50px">
                                {gallery?.length > 0 ? (
                                    <img
                                        src={gallery[selectedImage]}
                                        alt=""
                                        className="h-64 md:h-96"
                                    />
                                ) : (
                                    <Skeleton
                                        height="400px"
                                        width="400px"
                                        containerClassName="avatar-skeleton"
                                    />
                                )}
                            </FlexBox>
                            <FlexBox overflow="auto">
                                {gallery?.map((url, ind) => (
                                    <Box
                                        size={70}
                                        minWidth={70}
                                        bg="white"
                                        borderRadius="10px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        cursor="pointer"
                                        border="1px solid"
                                        key={ind}
                                        ml={ind === 0 && "auto"}
                                        mr={ind === gallery.length - 1 ? "auto" : "10px"}
                                        borderColor={
                                            selectedImage === ind ? "primary.main" : "gray.400"
                                        }
                                        onClick={handleImageClick(ind)}
                                    >
                                        <Avatar src={url} borderRadius="10px" size={40}/>
                                    </Box>
                                ))}
                            </FlexBox>
                        </Box>
                    </Grid>

                    <Grid item md={6} xs={12} alignItems="center">
                        <H1 mb="1rem">{product?.name || <Skeleton count={2}/>}</H1>

                        <FlexBox alignItems="center" mb="1rem">
                            <SemiSpan>Brand:</SemiSpan>
                            <H6 ml="8px">{product?.brand?.name || <Skeleton count={1}/>}</H6>
                        </FlexBox>

                        <FlexBox alignItems="center" mb="1rem">
                            <SemiSpan>Rated:</SemiSpan>
                            <Box ml="8px" mr="8px">
                                <Rating color="warn" value={product?.avg_ratings} outof={5}/>
                            </Box>
                            <H6>({product?.total_ratings})</H6>
                        </FlexBox>

                        <Box mb="24px">
                            <div className="flex">
                                {product?.discount_price ? (
                                    <>
                                        <H2 color="primary.main" mb="4px" lineHeight="1">
                                            {settings.currency} {product.discount_price}
                                        </H2>
                                        {product?.discount_price !== product?.price && (
                                            <H2 color="text.muted" mb="4px" ml="8px" lineHeight="1">
                                                <del>{settings.currency} {product?.price}</del>
                                            </H2>
                                        )}
                                    </>
                                ) : (
                                    <Skeleton count={1} width={100} height={40}/>
                                )}

                            </div>
                            <SemiSpan color="inherit">Stock Available</SemiSpan>
                        </Box>

                        {!cartItem?.quantity ? (
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                mb="36px"
                                onClick={() => handleCartAmountChange(1)}
                            >
                                Add to Cart
                            </Button>
                        ) : (
                            <FlexBox alignItems="center" mb="36px">
                                <Button
                                    p="9px"
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={() => handleCartAmountChange(cartItem?.quantity - 1)}
                                >
                                    <Icon variant="small">icons/minus</Icon>
                                </Button>
                                <H3 fontWeight="600" mx="20px">
                                    {cartItem?.quantity.toString().padStart(2, "0")}
                                </H3>
                                <Button
                                    p="9px"
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={() => handleCartAmountChange(cartItem?.quantity + 1)}
                                >
                                    <Icon variant="small">icons/plus</Icon>
                                </Button>
                            </FlexBox>
                        )}
                    </Grid>
                </Grid>
            </SkeletonTheme>
        </Box>
    );
};

export default ProductIntro;
