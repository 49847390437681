import Link from 'next/link';
import React, {Fragment, useCallback, useState} from 'react';
import Box from '../Box';
import Button from '../buttons/Button';
import Card, {CardProps} from '../Card';
import {Chip} from '../Chip';
import FlexBox from '../FlexBox';
import Icon from '../icon/Icon';
import Modal from '../modal/Modal';
import ProductIntro from '../products/ProductIntro';
import Rating from '../rating/Rating';
import {H3, SemiSpan} from '../Typography';
import {StyledProductCard1} from './ProductCardStyle';
import {useDispatch, useSelector} from "react-redux";
import {postWishList} from "../../helpers/backend_helper";
import {NotificationManager} from 'react-notifications';
import {getWishlist} from "@store/userReducer";

export interface ProductCard1Props extends CardProps {
    product: any
}

const ProductCard1: React.FC<ProductCard1Props> = ({product, ...props}) => {
    const [open, setOpen] = useState(false);
    // @ts-ignore
    const cartItem = useSelector(state => state.cart.products?.find((item) => item.id === product?.id))
    // @ts-ignore
    const wishItem = useSelector(state => state.user.wishlist?.find((item) => item.id === product?.id))
    const toggleDialog = useCallback(() => {
        setOpen((open) => !open);
    }, []);

    const dispatch = useDispatch()
    const handleCartAmountChange = amount => () => {
        dispatch({
            type: 'cart/update',
            payload: {
                id: product?.id,
                slug: product?.slug,
                name: product?.name,
                quantity: amount,
                price: +product?.discount_price,
                image: product?.image1,
            }
        })
    }
    // @ts-ignore
    const settings = useSelector(state => state.settings)
    let percent = ((+product?.price - +product?.discount_price) / +product?.price) * 100

    // @ts-ignore
    const user = useSelector(state => state.user)
    const handleWishList = () => {
        if(user.auth === true) {
            postWishList({id: product?.id}).then(({success, message}) => {
                if(success === true) {
                    NotificationManager.success(message)
                    dispatch(getWishlist({}))
                } else {
                    NotificationManager.error(message)
                }
            })
        } else {
            NotificationManager.warning("Please login to add wishlist")
        }

    }

    return (
        <StyledProductCard1 {...props}>
            <div className="image-holder">
                {product?.discount_price !== product?.price && (
                    <Chip
                        position="absolute"
                        bg="primary.main"
                        color="primary.text"
                        fontSize="10px"
                        fontWeight="600"
                        p="5px 10px"
                        top="10px"
                        left="10px"
                    >
                        {percent % 1 === 0 ? percent : percent.toFixed(2)}% off
                    </Chip>
                )}

                <FlexBox className="extra-icons">
                    <Icon
                        color="secondary"
                        variant="small"
                        mb="0.5rem"
                        onClick={toggleDialog}
                    >
                        icons/eye-alt
                    </Icon>

                    <Icon className="favorite-icon outlined-icon" color="primary" variant="small" onClick={() => handleWishList()}>
                        {wishItem ? 'icons/heart_filled' : 'icons/heart'}
                    </Icon>
                    {/* <Icon className="favorite-icon" color="primary" variant="small">
              heart-filled
            </Icon> */}
                </FlexBox>

                <Link href={`/product/${product?.slug}`}>
                    <a>
                        <div className="h-64 p-10 flex justify-center items-center">
                            <img src={product?.image1} style={{maxHeight: '100%'}} alt={""}
                            />
                        </div>
                    </a>
                </Link>
            </div>
            <div className="details">
                <FlexBox>
                    <Box flex="1 1 0" minWidth="0px" mr="0.5rem">
                        <Link href={`/product/${product?.slug}`}>
                            <a>
                                <H3
                                    className="title"
                                    fontSize="14px"
                                    textAlign="left"
                                    fontWeight="600"
                                    color="text.secondary"
                                    mb="10px"
                                    title={product?.name}
                                >
                                    {product?.name}
                                </H3>
                            </a>
                        </Link>

                        <Rating value={product?.avg_ratings || 0} outof={5} color="warn" readonly/>

                        <FlexBox alignItems="center" mt="10px">
                            <SemiSpan pr="0.5rem" fontWeight="600" color="primary.main">
                                {settings.data.currency_symbol} {product?.discount_price}
                            </SemiSpan>
                            {product?.price !== product?.discount_price && (
                                <SemiSpan color="text.muted" fontWeight="600">
                                    <del>{product?.price}</del>
                                </SemiSpan>
                            )}
                        </FlexBox>
                    </Box>

                    <FlexBox
                        flexDirection="column-reverse"
                        alignItems="center"
                        justifyContent={!!cartItem?.quantity ? 'space-between' : 'flex-start'}
                        width="30px"
                    >
                        {/* <div className="add-cart"> */}
                        <Button
                            variant="outlined"
                            color="primary"
                            padding="3px"
                            size="none"
                            borderColor="primary.light"
                            onClick={handleCartAmountChange((cartItem?.quantity || 0) + 1)}
                        >
                            <Icon variant="small">icons/plus</Icon>
                        </Button>

                        {!!cartItem?.quantity && (
                            <Fragment>
                                <SemiSpan color="text.primary" fontWeight="600">
                                    {cartItem?.quantity}
                                </SemiSpan>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    padding="3px"
                                    size="none"
                                    borderColor="primary.light"
                                    onClick={handleCartAmountChange(cartItem?.quantity - 1)}
                                >
                                    <Icon variant="small">icons/minus</Icon>
                                </Button>
                            </Fragment>
                        )}
                    </FlexBox>
                </FlexBox>
            </div>

            <Modal open={open} onClose={toggleDialog}>
                <Card p="1rem" position="relative">
                    <ProductIntro product={product}/>
                    <Box
                        position="absolute"
                        top="0.75rem"
                        right="0.75rem"
                        cursor="pointer"
                    >
                        <Icon
                            className="close"
                            color="primary"
                            variant="small"
                            onClick={toggleDialog}
                        >
                            icons/close
                        </Icon>
                    </Box>
                </Card>
            </Modal>
        </StyledProductCard1>
    );
};

export default ProductCard1;
